<template>
  <div>
    <div class="content-bottom-padded">
      <el-alert type="info" title="下载提示" show-icon :closable="false" effect="dark">
        <div>
          已经生成的文件只保留约二十四小时，请及时下载
        </div>
      </el-alert>
    </div>

    <el-container :style="{height: (wheight-180)+'px', border: '1px solid #eee'}">

<el-main>
        <el-aside width="200">
          <el-tabs   @tab-click="handleClick" :stretch="true" :height="200" :width="200">
            <el-tab-pane >
            <span slot="label"><i class="el-icon-s-order"></i> 订单、售后、维修</span>
              <el-tabs  tabPosition="left" v-model="activeNameOrder" @tab-click="handleClick">
                <el-tab-pane v-if="login_type !== 'admin' && login_type !== 'staff'" label="实体主订单" name="normal_master_order"></el-tab-pane>
                <el-tab-pane v-if="login_type !== 'admin' && login_type !== 'staff'" label="实体子订单" name="normal_order"></el-tab-pane>
                <el-tab-pane v-if="login_type !== 'admin' && login_type !== 'staff'" label="满赠订单信息" name="pull_gift_order"></el-tab-pane>
                <el-tab-pane label="售后列表" name="aftersale_record_count"></el-tab-pane>
                <el-tab-pane label="退款单列表" name="refund_record_count"></el-tab-pane>
                <el-tab-pane v-if="login_type !== 'admin' && login_type !== 'staff'" label="资金流水" name="capital"></el-tab-pane>
                <el-tab-pane label="售后视频素材" name="aftersales_material_video"></el-tab-pane>
                <el-tab-pane label="维修单列表" name="repair_order_list"></el-tab-pane>
                <el-tab-pane label="问卷管理列表" name="questionnaire_submit_list"></el-tab-pane>
                <el-tab-pane label="风险订单" name="risk_orders"></el-tab-pane>

                <el-main :style="{ height: (wheight-183)+'px'}  ">
                  <el-table :data="exportLogLists" width="100%"  v-loading="loading" stripe>
                    <el-table-column prop="log_id" label="ID" width="80"></el-table-column>
                    <el-table-column prop="file_name" label="文件名称"></el-table-column>
                    <el-table-column prop="finish_date" label="处理完成时间" width="160">
                      <template slot-scope="scope">
                        <span v-if="scope.row.handle_status == 'finish' || scope.row.handle_status == 'fail'">{{ scope.row.finish_date }}</span>
                      </template>
                    </el-table-column>
                    <el-table-column prop="handle_status" label="处理状态" width="80">
                      <template slot-scope="scope">
                        <span v-if="scope.row.handle_status == 'wait'">等待处理</span>
                        <span v-if="scope.row.handle_status == 'finish'">处理完成</span>
                        <span v-if="scope.row.handle_status == 'processing'">处理中</span>
                        <span v-if="scope.row.handle_status == 'fail'">处理失败</span>
                      </template>
                    </el-table-column>
                    <el-table-column label="操作" width="150" fixed="right">
                      <template slot-scope="scope">
                        <a v-if="scope.row.handle_status == 'finish'" href=" " @click.prevent="handleDown(scope.row.log_id)">下载</a> <!-- scope.row.file_url -->
                      </template>
                    </el-table-column>
                  </el-table>
                  <div class="content-center content-top-padded">
                    <el-pagination
                      background
                      layout="prev, pager, next, total"
                      @current-change="handleCurrentChange"
                      :current-page.sync="params.page"
                      :total="total_count" :page-size="params.pageSize">
                    </el-pagination>
                  </div>
                </el-main>
              </el-tabs>
            </el-tab-pane>
            <el-tab-pane >
            <span slot="label"><i class="el-icon-s-ticket"></i> 卡券</span>
              <el-tabs  v-model="activeNameCoupon" @tab-click="handleClick" tabPosition="left">
                <el-tab-pane label="优惠券领取记录" name="discountUser"></el-tab-pane>
                <el-tab-pane label="优惠券核销记录" name="couponUserDetail"></el-tab-pane>
                <el-tab-pane label="优惠券列表" name="coupon_list"></el-tab-pane>
                <el-tab-pane label="经销商优惠券列表" name="dealer_coupon_list"></el-tab-pane>
                <el-tab-pane label="口令卡生成日志" name="password_card"></el-tab-pane>
                <el-tab-pane label="口令卡明细" name="password_card_list"></el-tab-pane>
                <el-tab-pane label="门店券券码列表" name="distributor_coupon_code_pool"></el-tab-pane>
                <el-tab-pane label="门店券券码统计" name="distributor_coupon_code_pool_statics"></el-tab-pane>
                <el-tab-pane label="经销商优惠券列表" name="dealer_coupon_list"></el-tab-pane>
                <el-main :style="{ height: (wheight-183)+'px'}  ">
                  <el-table :data="exportLogLists" width="100%"  v-loading="loading" stripe>
                    <el-table-column prop="log_id" label="ID" width="80"></el-table-column>
                    <el-table-column prop="file_name" label="文件名称"></el-table-column>
                    <el-table-column prop="finish_date" label="处理完成时间" width="160">
                      <template slot-scope="scope">
                        <span v-if="scope.row.handle_status == 'finish' || scope.row.handle_status == 'fail'">{{ scope.row.finish_date }}</span>
                      </template>
                    </el-table-column>
                    <el-table-column prop="handle_status" label="处理状态" width="80">
                      <template slot-scope="scope">
                        <span v-if="scope.row.handle_status == 'wait'">等待处理</span>
                        <span v-if="scope.row.handle_status == 'finish'">处理完成</span>
                        <span v-if="scope.row.handle_status == 'processing'">处理中</span>
                        <span v-if="scope.row.handle_status == 'fail'">处理失败</span>
                      </template>
                    </el-table-column>
                    <el-table-column label="操作" width="150" fixed="right">
                      <template slot-scope="scope">
                        <a v-if="scope.row.handle_status == 'finish'" href=" " @click.prevent="handleDown(scope.row.log_id)">下载</a> <!-- scope.row.file_url -->
                      </template>
                    </el-table-column>
                  </el-table>
                  <div class="content-center content-top-padded">
                    <el-pagination
                      background
                      layout="prev, pager, next, total"
                      @current-change="handleCurrentChange"
                      :current-page.sync="params.page"
                      :total="total_count" :page-size="params.pageSize">
                    </el-pagination>
                  </div>
                </el-main>
              </el-tabs>
            </el-tab-pane>
            <el-tab-pane >
              <span slot="label"><i class="el-icon-s-shop"></i> 门店经销商</span>
              <el-tabs  v-model="activeNameShop" @tab-click="handleClick" tabPosition="left">
                <el-tab-pane label="店铺列表" name="distributor_list"></el-tab-pane>
                <el-tab-pane label="经销商列表" name="dealer_export"></el-tab-pane>
                <el-tab-pane label="店铺账号导出" name="distributor_operators"></el-tab-pane>
                <el-tab-pane label="经销商账号导出" name="dealer_operators"></el-tab-pane>
                <el-tab-pane label="代发货点列表" name="temporary_delivery_place"></el-tab-pane>
                <el-tab-pane label="店铺评价" name="distributors_evaluation"></el-tab-pane>
                <el-tab-pane label="店铺评价明细" name="distributor_evaluation_list"></el-tab-pane>
                <el-main :style="{ height: (wheight-183)+'px'}  ">
                  <el-table :data="exportLogLists" width="100%"  v-loading="loading" stripe>
                    <el-table-column prop="log_id" label="ID" width="80"></el-table-column>
                    <el-table-column prop="file_name" label="文件名称"></el-table-column>
                    <el-table-column prop="finish_date" label="处理完成时间" width="160">
                      <template slot-scope="scope">
                        <span v-if="scope.row.handle_status == 'finish' || scope.row.handle_status == 'fail'">{{ scope.row.finish_date }}</span>
                      </template>
                    </el-table-column>
                    <el-table-column prop="handle_status" label="处理状态" width="80">
                      <template slot-scope="scope">
                        <span v-if="scope.row.handle_status == 'wait'">等待处理</span>
                        <span v-if="scope.row.handle_status == 'finish'">处理完成</span>
                        <span v-if="scope.row.handle_status == 'processing'">处理中</span>
                        <span v-if="scope.row.handle_status == 'fail'">处理失败</span>
                      </template>
                    </el-table-column>
                    <el-table-column label="操作" width="150" fixed="right">
                      <template slot-scope="scope">
                        <a v-if="scope.row.handle_status == 'finish'" href=" " @click.prevent="handleDown(scope.row.log_id)">下载</a> <!-- scope.row.file_url -->
                      </template>
                    </el-table-column>
                  </el-table>
                  <div class="content-center content-top-padded">
                    <el-pagination
                      background
                      layout="prev, pager, next, total"
                      @current-change="handleCurrentChange"
                      :current-page.sync="params.page"
                      :total="total_count" :page-size="params.pageSize">
                    </el-pagination>
                  </div>
                </el-main>
              </el-tabs>
            </el-tab-pane>
            <el-tab-pane>
              <span slot="label"><i class="el-icon-s-promotion"></i> 营销</span>
              <el-tabs  v-model="activeNamePromotion" @tab-click="handleClick" tabPosition="left">
                <el-tab-pane label="问卷统计" name="questionnaire_statistics"></el-tab-pane>
                <el-tab-pane label="问卷详情" name="questionnaire_detail"></el-tab-pane>
                <el-tab-pane label="问卷管理列表" name="questionnaire_submit_list"></el-tab-pane>
                <el-tab-pane label="平台导流兑换码" name="activity_coupon_order_code"></el-tab-pane>
                <el-tab-pane label="线下活动报名记录" name="offline_activity_sign_up_log"></el-tab-pane>
                <el-tab-pane label="线下体验记录" name="offline_join_offline_activity_log"></el-tab-pane>
                <el-tab-pane label="线下活动搭售记录" name="offline_activity_relation_sale_log"></el-tab-pane>
                <el-tab-pane label="线下活动店铺" name="offline_activity_distributors"></el-tab-pane>
                <el-tab-pane label="线下活动店铺名额" name="offline_activity_distributors_quota"></el-tab-pane>
                <el-tab-pane label="满减活动" name="export_full_minus"></el-tab-pane>
                <el-tab-pane label="抽奖赠品" name="export_lottery_gift"></el-tab-pane>
                <el-tab-pane label="赠品门店库存" name="export_lottery_gift_shop_stock"></el-tab-pane>
                <el-tab-pane label="抽奖记录" name="lottery_record_list"></el-tab-pane>
                <el-tab-pane label="中奖名单" name="lottery_winning_list"></el-tab-pane>
                <el-tab-pane label="门店中奖限额" name="export_lottery_shop_winning_limit"></el-tab-pane>
                <el-tab-pane label="物料明细" name="material_detail"></el-tab-pane>
                <el-tab-pane label="新品发售门店" name="presale_activity_distributors"></el-tab-pane>
                <el-main :style="{ height: (wheight-183)+'px'}  ">
                  <el-table :data="exportLogLists" width="100%"  v-loading="loading" stripe>
                    <el-table-column prop="log_id" label="ID" width="80"></el-table-column>
                    <el-table-column prop="file_name" label="文件名称"></el-table-column>
                    <el-table-column prop="finish_date" label="处理完成时间" width="160">
                      <template slot-scope="scope">
                        <span v-if="scope.row.handle_status == 'finish' || scope.row.handle_status == 'fail'">{{ scope.row.finish_date }}</span>
                      </template>
                    </el-table-column>
                    <el-table-column prop="handle_status" label="处理状态" width="80">
                      <template slot-scope="scope">
                        <span v-if="scope.row.handle_status == 'wait'">等待处理</span>
                        <span v-if="scope.row.handle_status == 'finish'">处理完成</span>
                        <span v-if="scope.row.handle_status == 'processing'">处理中</span>
                        <span v-if="scope.row.handle_status == 'fail'">处理失败</span>
                      </template>
                    </el-table-column>
                    <el-table-column label="操作" width="150" fixed="right">
                      <template slot-scope="scope">
                        <a v-if="scope.row.handle_status == 'finish'" href=" " @click.prevent="handleDown(scope.row.log_id)">下载</a> <!-- scope.row.file_url -->
                      </template>
                    </el-table-column>
                  </el-table>
                  <div class="content-center content-top-padded">
                    <el-pagination
                      background
                      layout="prev, pager, next, total"
                      @current-change="handleCurrentChange"
                      :current-page.sync="params.page"
                      :total="total_count" :page-size="params.pageSize">
                    </el-pagination>
                  </div>
                </el-main>
              </el-tabs>
            </el-tab-pane>
            <el-tab-pane >
              <span slot="label"><i class="el-icon-s-goods"></i> 商品</span>
              <el-tabs  v-model="activeNameGoods" @tab-click="handleClick" tabPosition="left">
                <el-tab-pane label="店铺商品" name="distributor_items"></el-tab-pane>
                <el-tab-pane label="商品" name="items"></el-tab-pane>
                <el-tab-pane label="搭售商品" name="items_recommend"></el-tab-pane>
                <el-tab-pane label="商品统计" name="goods_data"></el-tab-pane>
                <el-tab-pane label="商品参数" name="goods_attribute_item_params"></el-tab-pane>
                <el-tab-pane label="商品品牌" name="goods_attribute_brand"></el-tab-pane>
<!--                <el-tab-pane label="分期商品" name="items_rel_fq_pay"></el-tab-pane>-->
                <el-tab-pane label="暂存商品" name="drafts_items"></el-tab-pane>
                <el-main :style="{ height: (wheight-183)+'px'}  ">
                  <el-table :data="exportLogLists" width="100%"  v-loading="loading" stripe>
                    <el-table-column prop="log_id" label="ID" width="80"></el-table-column>
                    <el-table-column prop="file_name" label="文件名称"></el-table-column>
                    <el-table-column prop="finish_date" label="处理完成时间" width="160">
                      <template slot-scope="scope">
                        <span v-if="scope.row.handle_status == 'finish' || scope.row.handle_status == 'fail'">{{ scope.row.finish_date }}</span>
                      </template>
                    </el-table-column>
                    <el-table-column prop="handle_status" label="处理状态" width="80">
                      <template slot-scope="scope">
                        <span v-if="scope.row.handle_status == 'wait'">等待处理</span>
                        <span v-if="scope.row.handle_status == 'finish'">处理完成</span>
                        <span v-if="scope.row.handle_status == 'processing'">处理中</span>
                        <span v-if="scope.row.handle_status == 'fail'">处理失败</span>
                      </template>
                    </el-table-column>
                    <el-table-column label="操作" width="150" fixed="right">
                      <template slot-scope="scope">
                        <a v-if="scope.row.handle_status == 'finish'" href=" " @click.prevent="handleDown(scope.row.log_id)">下载</a> <!-- scope.row.file_url -->
                      </template>
                    </el-table-column>
                  </el-table>
                  <div class="content-center content-top-padded">
                    <el-pagination
                      background
                      layout="prev, pager, next, total"
                      @current-change="handleCurrentChange"
                      :current-page.sync="params.page"
                      :total="total_count" :page-size="params.pageSize">
                    </el-pagination>
                  </div>
                </el-main>
              </el-tabs>
            </el-tab-pane>
            <el-tab-pane>
              <span slot="label"><i class="el-icon-s-data"></i> 统计/其他</span>
              <el-tabs  v-model="activeNameStatistics" @tab-click="handleClick" tabPosition="left">
                <el-tab-pane label="自闭环日志" name="selfhandle_log"></el-tab-pane>
                <el-tab-pane label="来源统计" name="statisticslists_export"></el-tab-pane>
                <el-tab-pane label="pos统计" name="order_data_pos_export"></el-tab-pane>
                <el-tab-pane label="hq统计" name="order_data_hq_export"></el-tab-pane>
                <el-tab-pane label="PII调用日志" name="pii_api_log"></el-tab-pane>
                <el-main :style="{ height: (wheight-183)+'px'}  ">
                  <el-table :data="exportLogLists" width="100%"  v-loading="loading" stripe>
                    <el-table-column prop="log_id" label="ID" width="80"></el-table-column>
                    <el-table-column prop="file_name" label="文件名称"></el-table-column>
                    <el-table-column prop="finish_date" label="处理完成时间" width="160">
                      <template slot-scope="scope">
                        <span v-if="scope.row.handle_status == 'finish' || scope.row.handle_status == 'fail'">{{ scope.row.finish_date }}</span>
                      </template>
                    </el-table-column>
                    <el-table-column prop="handle_status" label="处理状态" width="80">
                      <template slot-scope="scope">
                        <span v-if="scope.row.handle_status == 'wait'">等待处理</span>
                        <span v-if="scope.row.handle_status == 'finish'">处理完成</span>
                        <span v-if="scope.row.handle_status == 'processing'">处理中</span>
                        <span v-if="scope.row.handle_status == 'fail'">处理失败</span>
                      </template>
                    </el-table-column>
                    <el-table-column label="操作" width="150" fixed="right">
                      <template slot-scope="scope">
                        <a v-if="scope.row.handle_status == 'finish'" href=" " @click.prevent="handleDown(scope.row.log_id)">下载</a> <!-- scope.row.file_url -->
                      </template>
                    </el-table-column>
                  </el-table>
                  <div class="content-center content-top-padded">
                    <el-pagination
                      background
                      layout="prev, pager, next, total"
                      @current-change="handleCurrentChange"
                      :current-page.sync="params.page"
                      :total="total_count" :page-size="params.pageSize">
                    </el-pagination>
                  </div>
                </el-main>
              </el-tabs>
            </el-tab-pane>

          </el-tabs>

        </el-aside>

      </el-main>
    </el-container>

    <el-dialog
      title="请输入密码"
      :visible.sync="accountPasswordVisible"
      width="30%"
      :before-close="beforeClosePassword">
      <el-form>
        <el-form-item label="密码">
          <el-input v-model="accountPassword" class="input-m" type="password"></el-input>
        </el-form-item>
      </el-form>

      <span slot="footer" class="dialog-footer">
        <el-button @click="accountPasswordVisible = false">取 消</el-button>
        <el-button type="primary" @click="passwordDownload">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import {mapGetters} from 'vuex'
import {Message} from 'element-ui'
import {ExportLogList, ExportLogFileDown} from '../../../api/trade'

export default {
  props: ['getStatus'],
  data() {
    return {
      accountPasswordVisible: false,
      accountPassword: '',
      log_id: 0,
      activeName: "normal_master_order",
      activeNameOrder: "normal_master_order",
      activeNameCoupon: "discountUser",
      activeNameShop: "distributor_list",
      activeNamePromotion: "questionnaire_statistics",
      activeNameGoods: "distributor_items",
      activeNameStatistics: "selfhandle_log",
      create_time: '',
      exportLogLists: [],
      loading: false,
      total_count: 0,
      params: {
        page: 1,
        pageSize: 20,
        export_type: '',
        time_start_begin: '',
        time_start_end: '',
      },
    }
  },
  computed: {
    ...mapGetters([
      'wheight',
      'login_type',
    ])
  },
  methods: {
    // 切换tab
    handleClick(tab, event) {
      this.activeName = tab.name
      this.params.page = 1
      this.getExportLogLists(this.params)
    },
    handleCurrentChange(page_num) {
      this.params.page = page_num
      this.getExportLogLists(this.params)
    },
    dataSearch() {
      this.params.page = 1
      this.getExportLogLists(this.params)
    },
    getExportLogLists(params) {
      this.loading = true
      params.export_type = this.activeName
      ExportLogList(params).then(response => {
        this.exportLogLists = response.data.data.list
        this.total_count = response.data.data.total_count
        this.loading = false
      })
    },
    dateChange(val) {
      if (val && val.length > 0) {
        this.params.start_time = this.dateStrToTimeStamp(val[0] + ' 00:00:00')
        this.params.end_time = this.dateStrToTimeStamp(val[1] + ' 23:59:59')
      } else {
        this.params.start_time = ''
        this.params.end_time = ''
      }
      this.params.page = 1
      this.getExportLogLists(this.params)
    },
    dateStrToTimeStamp(str) {
      return Date.parse(new Date(str)) / 1000
    },
    beforeClosePassword () {
      this.accountPassword = ''
      this.accountPasswordVisible = false
    },
    handleDown(val) {
      if (this.activeName == 'password_card') {
        this.accountPasswordVisible = true
        this.log_id = val
      } else {
        let params = {
          log_id: val
        }
        this.downloadFile(params)
      }
    },
    passwordDownload () {
      if (!this.accountPassword) {
        this.$message.error('请输入密码')
        return false
      }
      let params = {
        log_id: this.log_id,
        password: this.accountPassword
      }
      this.downloadFile(params)
    },
    downloadFile (params) {
      ExportLogFileDown(params).then(response => {
        this.accountPasswordVisible = false
        this.accountPassword = ''

        var a = document.createElement("a");
        a.href = response.data.data.csv_data;
        a.download = response.data.data.file_name;
        document.body.appendChild(a);
        a.click();
        a.remove();

        // const url = this.genUrl(response.data.data.csv_data, {});//{}指的是表头，response.data.data.csv_data是后台返回来的数据
        // const a = document.createElement('a');
        // a.href = url;
        // console.log("exportlogfiledown:::::::response:")
        // console.log(response)
        // a.download = response.data.data.file_name;
        // a.click();
        // window.URL.revokeObjectURL(url);
      }).catch(err => {
        // this.$message.error("文件过期～")
      })
    },
    genUrl(encoded, options) {
      const dataBlob = new Blob([`\ufeff${encoded}`], {type: 'text/plain;charset=utf-8'});//返回的格式
      return window.URL.createObjectURL(dataBlob);
    },
  },
  mounted() {
    if (this.$route.query.active_type) {
      // this.activeName = 'normal_master_order'
      this.activeName = this.$route.query.active_type
    }
    this.getExportLogLists(this.params)
  },
  watch: {
    getStatus(val) {
      if (val) {
        this.getExportLogLists(this.params)
      }
    }
  }
}
</script>
<style scoped lang="scss">
.el-row {
  margin-bottom: 20px;

  &:last-child {
    margin-bottom: 0;
  }
}

.el-col {
  border-radius: 4px;
}

.bg-purple-dark {
  background: #99a9bf;
}

.bg-purple {
  background: #d3dce6;
}

.bg-purple-light {
  background: #e5e9f2;
}

.grid-content {
  border-radius: 4px;
  min-height: 10px;
}

.row-bg {
  padding: 10px 0;
  background-color: #f9fafc;
}

.text-muted {
  color: #999;
}
</style>
